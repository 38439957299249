header {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
}

.header__intro {
  color: var(--color-teal);
  font-family: 'Courier', 'Courier New';
  font-weight: 600;
  font-size: 1.3rem;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__resume {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 5rem;
}

.header__socials::after { /* line after icons */
  content: '';
  width: 1px;
  height: 4rem;
  background: var(--color-primary);
}

.me {
  width: 22rem;
  height: 22rem;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 3rem;
  padding: 1.5rem;
}

.header__email-div {
  display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.header__email {
  position: absolute;
  right: -7rem;
  bottom: 12rem;
  transform: rotate(90deg);
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
}


.header__desc {
  max-width: 450px;
  padding: auto;
  margin: 0 auto;
  font-size: 1rem;
}

/* MEDIA QUERIES: MEDIUM DEVICES, TABLETS, ETC */

@media screen and (max-width: 1024px) {
  .me {
    margin-top: 1.8rem;
  }
}

/* MEDIA QUERIES: SMALL DEVICES, PHONES, ETC */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .header__email {
    display: none;
  }
}