#contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem 100px 1rem;
}

.contact__connect {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.contact__description {
  margin: 0 5rem 2rem;
  text-align: center;
  font-size: clamp(14px, 2.5vw, 18px);
}