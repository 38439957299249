@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box; 
  list-style: none; /* remove bullets */
  text-decoration: none; /* remove underlines from links */
}

:root {
  --color-bg: #1f1f38; /* background color for site */
  --color-bg-variant: #2c2c6c; /* background color for cards */
  --color-primary: #4db5ff; /* color for primary buttons */
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-teal: #6dffe7;

  --transition: all 400ms ease;

  --container-width-lg: 75%; /* width of general container class */
  --container-width-md: 86%; /* media query settings */
  --container-width-sm: 90%;
}

html, body {
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif; /* font for site, can tweak */
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(../src/assets/bg-texture.png); /* rugged texture for background*/
}

/* GENERAL STYLES */
.container {
  width: var(--container-width-lg);
  margin: 0 auto; /* horizontal middle */
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem; /* whitespace around different sections */
  height: 100vh;
}

section > h1,
section > h5 { /* headers for each section */
  text-align: center;
}

section > h1 {
  color: var(--color-white);
  margin-bottom: 3rem;
}

section > h5 {
  color: var(--color-teal);
  padding-top: 5rem;
  font-family: 'Courier', 'Courier New';
  font-weight: 600;
  font-size: 1.3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  font-size: 1.2rem;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* MEDIA QUERIES: MEDIUM DEVICES, TABLETS, ETC */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    height: 100%;
    margin-top: 6rem
  }
}

/* MEDIA QUERIES: SMALL DEVICES, PHONES, ETC */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem
  }
}